body {
  margin: 0;
  font-family: "Raleway", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

code {
  font-family: "Raleway", sans-serif, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.marker {
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;

  white-space: nowrap;
}

.marker.show {
  opacity: 1;

  visibility: visible;
}
